import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCallHistory } from '../../../api/adapters/call';
import { deleteConversation } from '../../../api/adapters/chat';
import { updateNotification } from '../../../api/adapters/notification';
import FileIc from '../../../assets/images/file-ic.svg';
import DpPlaceholder from '../../../assets/images/no-dp.svg';
import SensitiveIc from '../../../assets/images/sensitive-ic.svg';
import { CommonContext } from '../../../context/CommonState';
import { deleteNotification } from '../../../redux/actions/notification';
import {
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import {
    formatTalkedHours,
    formatTimeShort,
    getFullName,
    getRelativeTimeStringForConversation,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../ModalPopUp';
import LoadingButton from '../mini-components/LoadingButton';

function ChatTabs(props) {
    const {
        conversations,
        selectedConversation,
        setConversations,
        setSelectedConversation,
    } = props;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [callHistory, setCallHistory] = useState([]);
    const [deletePopup, setDeletePopup] = useState(false);

    const {
        setMobileLayout,
        setCertificate,
        setMobileLayoutUser,
        isMobile,
        setChaHistory,
    } = useContext(CommonContext);

    const user = useSelector((state) => state.user);
    const notification = useSelector((state) => state.notification);
    const allMessages = notification.filter(
        (item) =>
            item.sNotificationType === 'TEXT' ||
            item.sNotificationType === 'IMAGE' ||
            item.sNotificationType === 'AUDIO'
    );

    const { data: callHistoryData, error: historyError } = useQuery({
        queryKey: ['calls', i18n.language?.split('-')[0]],
        queryFn: () =>
            getCallHistory(i18n.language?.split('-')[0]).then((res) => {
                return res.data;
            }),
    });

    const { mutate: mutateUpdateNotification } = useMutation({
        mutationFn: (data) =>
            updateNotification(i18n.language?.split('-')[0], data),
        onSuccess: () => {
            dispatch(deleteNotification(selectedConversation));
        },
        onError: (error) => handleApiError(error, t, navigate),
    });

    const { mutate: mutateDeleteConversation, isLoading: isDeleting } =
        useMutation({
            mutationFn: () =>
                deleteConversation(
                    i18n.language.split('-')[0],
                    selectedConversation
                ),
            onSuccess: (response) => {
                notify('success', response.message);
                setConversations((prevConversations) =>
                    prevConversations.filter(
                        (conversation) =>
                            conversation._id !== selectedConversation
                    )
                );
                setSelectedConversation(null);
                setDeletePopup(false);
            },
            onError: (error) => handleApiError(error, t, navigate),
        });

    useEffect(() => {
        if (historyError) handleQueryError(historyError, navigate);
    }, [historyError]);

    useEffect(() => {
        setCertificate(false);
    }, []);

    useEffect(() => {
        if (callHistoryData) {
            callHistoryData.sort(compareByTimestamp);
            setCallHistory(callHistoryData);
        }
    }, [callHistoryData]);

    function compareByTimestamp(a, b) {
        const timestampA = new Date(a.dCreatedAt).getTime();
        const timestampB = new Date(b.dCreatedAt).getTime();
        return timestampB - timestampA;
    }

    const renderLastText = (conversation) => {
        if (conversation?.bIsBlocked)
            return (
                <>
                    <img src={SensitiveIc} alt='' />
                    <span>{t('chat.sensitive_message')}</span>
                </>
            );
        if (conversation?.eMessageType === 'IMAGE')
            return (
                <>
                    <img src={FileIc} alt='' />
                    <span>{t('chat.image')}</span>
                </>
            );
        else if (conversation?.eMessageType === 'AUDIO')
            return (
                <>
                    <img src='/images/record_ic.svg' alt='' />
                    <span>{t('chat.audio')}</span>
                </>
            );
        else if (!conversation?.sLastText)
            return <span>{t('chat.click_here_to_chat')}</span>;
        return <span>{conversation.sLastText}</span>;
    };

    return (
        <>
            <div className='custom-tabs'>
                <Tabs
                    defaultActiveKey='call'
                    id='uncontrolled-tab-example'
                    className='tabs-wrapper'
                    onSelect={() => {
                        setCertificate(false);
                        setSelectedConversation(null);
                    }}
                >
                    <Tab eventKey='call' title={t('chat.chat')}>
                        <ul className='chat-box-users'>
                            {conversations?.length
                                ? conversations?.map((conversation) => {
                                      return (
                                          <li
                                              key={conversation._id}
                                              className={`user-item ${
                                                  selectedConversation ===
                                                  conversation._id
                                                      ? 'active-user'
                                                      : ''
                                              }`}
                                              onClick={() => {
                                                  isMobile &&
                                                      setMobileLayout(true);
                                                  isMobile &&
                                                      setMobileLayoutUser(true);
                                                  setSelectedConversation(
                                                      conversation._id
                                                  );
                                                  setCertificate(false);
                                                  if (allMessages.length > 0) {
                                                      mutateUpdateNotification(
                                                          conversation._id
                                                      );
                                                  }
                                              }}
                                          >
                                              <div className='user-dp'>
                                                  <img
                                                      src={
                                                          conversation
                                                              .aParticipants[0]
                                                              .sProfilePicUrl ||
                                                          DpPlaceholder
                                                      }
                                                      alt=''
                                                  />
                                              </div>
                                              <div className='chat-details'>
                                                  <div className='name-time'>
                                                      <p>
                                                          {/* {getFullName(
                                                              conversation
                                                                  .aParticipants[0]
                                                                  .oName
                                                          )} */}
                                                          {
                                                              conversation
                                                                  .aParticipants[0]
                                                                  .oName
                                                                  ?.sFirstName
                                                          }
                                                      </p>
                                                      <p className='xsm'>
                                                          {getRelativeTimeStringForConversation(
                                                              conversation?.dLastMessageTime ||
                                                                  conversation.dUpdatedAt,
                                                              t,
                                                              i18n.language.split(
                                                                  '-'
                                                              )[0]
                                                          )}
                                                      </p>
                                                  </div>
                                                  <div className='last-msg'>
                                                      {
                                                          <p className='xsm'>
                                                              {renderLastText(
                                                                  conversation
                                                              )}
                                                          </p>
                                                      }

                                                      {conversation?.nUnreadMessages &&
                                                      selectedConversation !==
                                                          conversation._id &&
                                                      conversation.iLastConnectedUser !==
                                                          conversation
                                                              .aParticipants[1]
                                                              ._id ? (
                                                          <div className='msg-numbers'>
                                                              {
                                                                  conversation?.nUnreadMessages
                                                              }
                                                          </div>
                                                      ) : (
                                                          ''
                                                      )}
                                                  </div>
                                              </div>
                                              {selectedConversation ===
                                                  conversation._id && (
                                                  <div className='delete-user'>
                                                      <button
                                                          className='simple-btn'
                                                          onClick={() =>
                                                              setDeletePopup(
                                                                  true
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              src='images/delete-red.svg'
                                                              alt=''
                                                          />
                                                      </button>
                                                  </div>
                                              )}
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </Tab>
                    <Tab eventKey='call_history' title={t('chat.call_history')}>
                        <ul className='chat-box-users'>
                            <li className='user-item call-duration-certificate'>
                                {/* <div className='blue-box'>
                                <div className='total-time'>
                                    <p className=''>Carlos</p>
                                    <h2>3hr 45min</h2>
                                    <p className='sm'>Total 10 Calls</p>
                                </div>
                                <button
                                    className='simple-btn'
                                    onClick={() => {
                                        setChaHistory(false);
                                        setCertificate(true);
                                        setMobileLayout(true);
                                        isMobile && setMobileLayoutUser(true);
                                    }}
                                >
                                    <img
                                        src='/images/cirtificate-button.svg'
                                        alt=''
                                    />
                                </button>
                            </div> */}
                                <div className='call-duration profile-card'>
                                    <div className='mf-card'>
                                        <h2>
                                            {formatTalkedHours(
                                                i18n.language.split('-')[0],
                                                user.totalCallSeconds
                                            )}
                                        </h2>
                                        <p className='small'>
                                            {user.totalCalls}{' '}
                                            {t('profile.calls')}
                                        </p>
                                    </div>
                                    {user.accountType === 'S' ? (
                                        <button
                                            className={`mf-card ${
                                                user.totalCallSeconds < 3600
                                                    ? 'disable-action'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                if (
                                                    user.totalCallSeconds < 3600
                                                ) {
                                                    notify(
                                                        'error',
                                                        t('chat.min_1hr')
                                                    );
                                                    return;
                                                }
                                                setChaHistory(false);
                                                setCertificate(true);
                                                setMobileLayout(true);
                                                isMobile &&
                                                    setMobileLayoutUser(true);
                                            }}
                                        >
                                            <img
                                                src='/images/icons/certificate-ic.svg'
                                                alt=''
                                            />
                                            <p className='sm'>
                                                {t('get_certificate')}
                                            </p>
                                        </button>
                                    ) : null}
                                </div>
                            </li>
                            {callHistory.map((user, index) => {
                                return (
                                    <li className={`user-item `} key={index}>
                                        <div className='user-dp'>
                                            <img
                                                src={
                                                    user?.oDialoguer
                                                        ?.sProfilePicUrl ||
                                                    DpPlaceholder
                                                }
                                                alt=''
                                            />
                                        </div>
                                        <div className='chat-details'>
                                            <div className='history'>
                                                <p className=''>
                                                    {getFullName(
                                                        user?.oDialoguer?.oName
                                                    )}
                                                </p>
                                                <img
                                                    src={
                                                        !user?.nCallDuration &&
                                                        user?.iCaller !==
                                                            getUserId()
                                                            ? '/images/miss-call.svg'
                                                            : user?.iCaller ===
                                                              getUserId()
                                                            ? '/images/outgoing.svg'
                                                            : '/images/history-ic1.svg'
                                                    }
                                                    alt=''
                                                />
                                            </div>
                                            <div className='history'>
                                                <span className='time-ic'>
                                                    <img
                                                        src='/images/time-ic2.svg'
                                                        alt=''
                                                    />

                                                    <p className='sm grey-text'>
                                                        {getRelativeTimeStringForConversation(
                                                            user.dCreatedAt,
                                                            t,
                                                            i18n.language.split(
                                                                '-'
                                                            )[0]
                                                        )}
                                                    </p>
                                                </span>
                                                <p className='sm grey-text'>
                                                    {formatTimeShort(
                                                        user?.nCallDuration,
                                                        t
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </Tab>
                </Tabs>
            </div>
            {/* DELETE CONVERSATION POPUP  */}
            <ModalPopUp
                show={deletePopup}
                onHide={() => setDeletePopup(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>{t('chat.are_you')}</h3>

                        <p className='sm'>{t('chat.you_sure')}</p>

                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setDeletePopup(false)}
                            >
                                {t('chat.no_dont')}
                            </button>
                            <button
                                className='simple-btn'
                                disabled={isDeleting}
                                onClick={mutateDeleteConversation}
                            >
                                {isDeleting ? (
                                    <LoadingButton />
                                ) : (
                                    t('chat.yes_delete')
                                )}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

export default ChatTabs;
