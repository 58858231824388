import apiClient from '../client/api-client';
export const getNotification = async (language) => {
    const url = `user/notification/list`;

    return apiClient({
        url,
        method: 'GET',
        headers: { 'Accept-Language': language },
    });
};

export const updateNotification = async (language, data) => {
    const url = `user/notification/${data}/update`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
        data,
    });
};

export const updateMultipleNotification = async (language, data) => {
    const url = `/user/notification/update`;

    return apiClient({
        url,
        method: 'PATCH',
        headers: { 'Accept-Language': language },
        data,
    });
};
