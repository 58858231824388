import { useMutation } from '@tanstack/react-query';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    updateMultipleNotification,
    updateNotification,
} from '../../../api/adapters/notification';
import { deleteNotification } from '../../../redux/actions/notification';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { getRelativeTimeStringForNotification } from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';

function NotificationToggle({
    setNotificationToggle,
    allMessages,
    refNotification,
    updateCount,
}) {
    const { t, i18n } = useTranslation();
    // const countOccurrences = allMessages.reduce((acc, message) => {
    //     acc[message.iConversationId] = (acc[message.iConversationId] || 0) + 1;
    //     return acc;
    // }, {});
    const filteredAllMessages = allMessages.filter((message) =>
        Object.values(message).some((value) => value)
    );

    const countOccurrences = filteredAllMessages.reduce((acc, message) => {
        // acc[message.iConversationId] = (acc[message.iConversationId] || 0) + 1;
        if (!message.bIsRead) {
            acc[message.iConversationId] =
                (acc[message.iConversationId] || 0) + 1;
        }
        return acc;
    }, {});

    const seenIds = new Set();
    const duplicates = filteredAllMessages.filter((obj, index, data) => {
        // if (
        //     seenIds.has(obj.iConversationId) &&
        //     obj.sNotificationType === data[index].sNotificationType &&
        //     Object.values(obj).some((value) => value)
        // ) {
        //     // Already seen this ID, don't include it in the array
        //     return false;
        // } else {
        //     // Add this ID to the set
        seenIds.add(obj.iConversationId);
        // Include this item in the array whether it's a duplicate or not
        // return true;
        return Object.values(obj).some((value) => value);
        // }
    });
    const { mutate: mutateUpdateNotification } = useMutation({
        mutationFn: (type) =>
            updateMultipleNotification(i18n.language.split('-')[0], type),
        onSuccess: () => {
            updateCount();
            refNotification();
            notify('success', t('read_all_notify'));
        },
        onError: (error) => {
            console.log(error);
        },
    });
    return (
        <>
            <div
                className='back-drop-layer'
                onClick={() => {
                    setNotificationToggle(false);
                }}
            ></div>
            <div className='notification-wrapper'>
                <button
                    className='popup-close-btn'
                    onClick={() => {
                        setNotificationToggle(false);
                    }}
                >
                    <img src='/images/icons/close.svg' alt='' />
                </button>
                <div
                    className={`notify-container ${
                        duplicates.length > 20 ? 'max-h-96 overflow-y-auto' : ''
                    }`}
                >
                    <div className='d-flex notify-header sticky-top justify-content-between align-items-center pb-2'>
                        <p className='lg pb-0 notify-container-heading'>
                            {t('notifications')}
                        </p>
                        {duplicates.length > 0 && (
                            <button
                                onClick={() => {
                                    mutateUpdateNotification({ type: 'ALL' });
                                    setNotificationToggle(false);
                                }}
                                className='simple-btn read-all-btn'
                            >
                                <img
                                    src='/images/icons/checkmark-circle-outline-svgrepo-com.svg'
                                    alt=''
                                />
                            </button>
                        )}
                    </div>
                    {filteredAllMessages.length === 0 ||
                    duplicates.length === 0 ? (
                        <span>{t('no_new_notifications')}</span>
                    ) : (
                        ''
                    )}
                    {duplicates.length > 0
                        ? duplicates.map((message, index) => {
                              return (
                                  <NotificationCard
                                      seen={message?.bIsRead}
                                      t={t}
                                      i={i18n}
                                      countOccurrences={
                                          countOccurrences[
                                              message.iConversationId
                                          ]
                                      }
                                      notificationIndex={
                                          message.iConversationId
                                      }
                                      key={index}
                                      type={message.sNotificationType}
                                      sender={message}
                                      setNotificationToggle={
                                          setNotificationToggle
                                      }
                                  />
                              );
                          })
                        : filteredAllMessages.map((message, index) => {
                              //   allMessages
                              //       .filter((message) =>
                              //           Object.values(message).some((value) => value)
                              //       )
                              //       .map((message, index) => {
                              return (
                                  <NotificationCard
                                      t={t}
                                      i={i18n}
                                      notificationIndex={
                                          message.iConversationId
                                      }
                                      key={index}
                                      type={message.sNotificationType}
                                      sender={message}
                                      setNotificationToggle={
                                          setNotificationToggle
                                      }
                                  />
                              );
                          })}
                    {/* <NotificationCard type={'logo'} />
                    <NotificationCard type={'close'} />
                    <NotificationCard type={'action'} /> */}
                </div>
            </div>
        </>
    );
}

const NotificationCard = ({
    t,
    i,
    countOccurrences,
    notificationIndex,
    type,
    seen,
    sender,
    setNotificationToggle,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };
    const isYesterday = (date) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return (
            date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear()
        );
    };

    const { mutate: readNotification, isLoading: updateNotificationLoading } =
        useMutation({
            mutationFn: (data) =>
                updateNotification(i.language.split('-')[0], data),
            onSuccess: () => {
                dispatch(deleteNotification(notificationIndex));
                setNotificationToggle(false);
                if (
                    sender.sNotificationType === 'TEXT' ||
                    sender.sNotificationType === 'AUDIO' ||
                    sender.sNotificationType === 'IMAGE'
                ) {
                    navigate('/chat', {
                        state: {
                            conversationId: sender.iConversationId,
                        },
                    });
                } else if (
                    sender.sNotificationType === 'APPOINTMENT' ||
                    sender.sNotificationType === 'RESCHEDULED' ||
                    sender.sNotificationType === 'REJECTED'
                ) {
                    navigate('/appointments/request');
                } else if (sender.sNotificationType === 'ACCEPTED') {
                    navigate('/appointments/schedule');
                } else {
                    if (user.accountType === 'R') navigate('/');
                    else navigate('/appointments/schedule');
                }
            },
            onError: (error) => handleApiError(error, t, navigate),
        });
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <div
            className={`notify-item ${seen ? 'seen' : ''}`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if (!seen) {
                    readNotification(sender._id);
                }
            }}
        >
            <div className='notify-content'>
                <p className='sm'>
                    {t(`${sender.sNotificationType}`, {
                        name: sender.sSenderName,
                        time: moment(sender.dStartTime)
                            .tz(userTimezone)
                            .format('HH:mm'),
                        date: moment(sender.dStartTime)
                            .tz(userTimezone)
                            .locale(i.language.split('-')[0])
                            .format('DD MMM YYYY'),
                    })}
                </p>
                <p className='xsm mt-2'>
                    {isToday(new Date(sender.dCreatedAt))
                        ? `${t('today')}, ` +
                          new Date(sender.dCreatedAt).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                          })
                        : isYesterday(new Date(sender.dCreatedAt))
                        ? `${t('yesterday')}, ` +
                          new Date(sender.dCreatedAt).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                          })
                        : getRelativeTimeStringForNotification(
                              sender.dCreatedAt,
                              i.language.split('-')[0],
                              userTimezone
                          )}
                </p>
            </div>
            <div className='notify-action'>
                {type === 'ACCEPTED' && (
                    <button className='simple-btn'>
                        <img src='/images/blue_tick.svg' alt='' />
                    </button>
                )}
                {type === 'ALERT' && (
                    <button className='simple-btn'>
                        <img src='/images/time.svg' alt='' />
                    </button>
                )}
                {type === 'REJECTED' || type === 'CANCELED' ? (
                    <button className='simple-btn'>
                        <img src='/images/notify-close.svg' alt='' />
                    </button>
                ) : (
                    ''
                )}
                {type === 'TEXT' || type === 'IMAGE' || type === 'AUDIO' ? (
                    <>
                        {/* <img src='/images/logo-notify.svg' alt='' /> */}
                        {Number(countOccurrences) > 1 && (
                            <span className='notify-count ml-5'>
                                {countOccurrences}
                            </span>
                        )}
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
export default NotificationToggle;
