import moment from 'moment';
import 'moment/locale/de'; // German Locale
import 'moment/locale/es'; // Spanish Locale

export const convertToTimeOrDataTime = (date) => {
    const now = moment();
    const today = now.clone().startOf('day');
    const dateTime = moment(date);

    if (dateTime.isSame(today, 'd')) return dateTime.format('HH:mm');
    else return dateTime.format('YYYY-MM-DD HH:mm');
};

export const getTimeFromDate = (date) => moment(date).format('HH:mm');

export const getRelativeDateString = (date, t, locale) => {
    const messageMoment = moment(date);
    messageMoment.locale(locale);

    const getFormattedDay = (momentObj) => {
        const formatted = momentObj.calendar(null, {
            sameDay: `[${t('chat.days.Today')}]`,
            lastDay: `[${t('chat.days.Yesterday')}]`,
            nextDay: `[${t('chat.days.Tomorrow')}]`,
            lastWeek: 'dddd',
            sameElse: 'DD MMMM YYYY',
        });
        // Extract only the day part
        return formatted.split(' at ')[0];
    };

    const formattedStartDay = getFormattedDay(messageMoment);
    return formattedStartDay;
};

export const getRelativeTimeStringForConversation = (date, t, locale) => {
    const messageMoment = moment(date);
    messageMoment.locale(locale);

    const formattedDate = messageMoment.calendar(null, {
        sameDay: `[${t('chat.days.Today')}], HH:mm`,
        lastDay: `[${t('chat.days.Yesterday')}], HH:mm`,
        lastWeek: 'dddd, HH:mm',
        sameElse: 'DD MMMM YYYY, HH:mm',
    });

    return formattedDate;
};

export const getExpandedDateString = (date, locale) =>
    moment(date).locale(locale).format('DD MMMM YYYY');

export const getDateStringWithDay = (date, locale) =>
    moment(date).locale(locale).format('ddd, DD MMM YYYY');

export const getUserFullName = (user) => {
    const { firstName, middleName, lastName } = user;
    // return `${firstName} ${middleName || ''} ${lastName || ''}`;
    let name;

    if (firstName) name = firstName;
    if (middleName) name += ' ' + middleName;
    if (lastName) name += ' ' + lastName;

    return name;
};

export const getFullName = (oName) => {
    // return `${oName?.sFirstName || ''} ${oName?.sMiddleName || ''} ${
    //     oName?.sLastName || ''
    // }`;

    let name;

    if (oName?.sFirstName) name = oName.sFirstName;
    // if (oName?.sMiddleName) name += ' ' + oName.sMiddleName;
    // if (oName?.sLastName) name += ' ' + oName.sLastName;

    return name;
};

export const formatTalkedHours = (locale, seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const labels = {
        es: { hour: 'h', minute: 'min' }, // Spanish
        de: { hour: 'Std', minute: 'Min' }, // German
        en: { hour: 'hr', minute: 'min' }, // English
    };

    const { hour: hourLabel, minute: minuteLabel } =
        labels[locale] || labels['en'];

    const parts = [];
    if (hours > 0) parts.push(`${hours} ${hourLabel}`);
    if (minutes > 0) parts.push(`${minutes} ${minuteLabel}`);
    if (parts.length === 0) parts.push(`0 ${minuteLabel}`);

    return parts.join(' ');
};

export const formatTime = (nMinutes, t) => {
    const hours = Math.floor(nMinutes / 60);
    const minutes = nMinutes % 60;

    if (hours > 0 && minutes > 0) {
        return `${hours} hr ${minutes} min`;
    } else if (hours > 0) {
        return `${hours} hr`;
    } else if (minutes > 0) {
        return `${minutes} min`;
    }

    return `0 min`; // Default case
};

export const formatTimeShort = (nSeconds, t) => {
    const nMinutes = Math.floor(nSeconds / 60);
    const hours = Math.floor(nMinutes / 60);
    const minutes = nMinutes % 60;
    const seconds = nSeconds % 60;

    if (hours > 0 && minutes > 0)
        return `${hours} ${t('hr')} ${minutes} ${t('min')}`;
    else if (hours > 0) return `${hours} ${t('hr')}`;
    else if (minutes > 0 && seconds > 0)
        return `${minutes} ${t('min')} ${seconds} ${t('sec')}`;
    else if (minutes > 0) return `${minutes} ${t('min')}`;

    return nSeconds ? `${seconds} ${t('sec')}` : ``; // Default case
};

export const formatDateRange = (date, locale) => {
    // return moment(date).locale(locale).format('Do MMM');
    if (locale === 'es') {
        // Custom format for Spanish
        const day = moment(date).format('D [de]'); // Day without ordinal
        const month = moment(date).format('MMM').replace('.', ''); // Month abbreviation without period
        return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`; // Capitalize month
    } else {
        // Default format for other locales (including English)
        return moment(date).locale(locale).format('Do MMM');
    }
};

export const getRelativeTimeStringForNotification = (
    date,
    locale,
    timezone
) => {
    const messageMoment = moment(date).tz(timezone);
    messageMoment.locale(locale);

    const addOrdinalSuffix = (day) => {
        const suffix =
            day % 10 === 1 && day !== 11
                ? 'st'
                : day % 10 === 2 && day !== 12
                ? 'nd'
                : day % 10 === 3 && day !== 13
                ? 'rd'
                : 'th';

        return `${day}${suffix}`;
    };

    const day = addOrdinalSuffix(messageMoment.date());
    const formattedString = `${day} ${messageMoment.format(
        'MMMM YYYY, HH:mm'
    )}`;

    return formattedString;
};
