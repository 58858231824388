import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AudioPlayer from '../../../components/common-component/AudioPlayer';
import VideoPlayer from '../../../components/common-component/VideoPlayer';
const EngAboutStory = () => {
    const { search } = useLocation();
    const { i18n } = useTranslation();

    const queryParams = new URLSearchParams(search);
    const lang = queryParams.get('lang');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, []);
    return (
        <div className='content-wrapper'>
            <div className='player-with-heading'>
                <h2 className='blue-text'>My Story Starts From The Ending</h2>
                <AudioPlayer />
            </div>
            <p>
                In 2021, I had everything a young university graduate could
                dream of: a prosperous career in finance, working abroad in one
                of the biggest European banks, a meaningful connection with my
                dream woman, and the opportunity to travel the world.
            </p>
            <p className='blue-text'>It was great until it wasn't.</p>
            <p>
                My dream life started to fall apart like a row of dominoes. I
                was unprepared for a serious relationship and ended things with
                my partner. Only a year later did I recognize what a huge
                mistake I made. I tried to win her back, but the door was
                closed. This left me feeling empty and questioning whether
                ending the relationship was the right decision. I kept asking
                myself: how could I have missed out on this connection?
            </p>
            <VideoPlayer />
            <p>
                At the same time, my dream career didn't seem so dreamy after
                all. I realized finance wasn't my calling anymore, making me
                demotivated and without direction.
            </p>
            <p>
                On the surface, you couldn't tell every cell in my body was
                aching. But that's the thing:{' '}
                <span className='blue-text'>loneliness is silent</span>. It
                makes you tired of trying to change things, hopeless that
                there's no one and nothing for you.
            </p>
            <p>
                <span className='blue-text'>
                    I made one of the most pivotal decisions in my life.
                </span>{' '}
                I left my job, packed my bags, and returned home to Tenerife to
                figure out what to do next. I couldn't wrap my head around the
                fact that I had everything, but the decisions I had made left me
                feeling more lost and emptier than ever before.
            </p>
            <p>
                You might think:{' '}
                <span className='blue-text'>
                    ‘You're crazy, there's no better place to live than the
                    Canary Islands.'
                </span>
                But for me, it felt more lonely than anything else. People in my
                circle couldn't understand why I was so unhappy and feeling out
                of place.{' '}
                <span className='blue-text'>
                    Being among people and feeling misunderstood deepened my
                    loneliness.
                </span>
            </p>
            <p>
                <span className='blue-text'>
                    But a lifeline to my loneliness had always been there
                </span>
                . It was my former boss, parents, strangers and international
                friends, who lived thousands of kilometres away and yet still
                checked in with me.{' '}
                <span className='blue-text'>
                    They were there for me, holding a safe space
                </span>
                . Slowly I opened up and this was like taking my first breath of
                fresh air. Many would say,{' '}
                <span className='blue-text'>
                    'Hey, but you're so young; just go out and meet people.'
                </span>{' '}
                This grave misconception leads to even more loneliness because
                people don't feel eligible to ask for help. However, statistics
                paint a completely different picture.
            </p>
            <p>
                Loneliness is an invisible and serious issue that affects{' '}
                <span className='blue-text'>
                    13.4% of the Spanish population
                </span>{' '}
                and over{' '}
                <span className='blue-text'>
                    30 million people across the EU
                </span>
                . Loneliness leads to severe consequences on health and
                well-being, with many people developing depression, anxiety, and
                poor physical health.
            </p>
            <p>
                I knew I had to do something. So,{' '}
                <span className='blue-text'>
                    I created an online safe space – Dialogæ
                </span>
                . The same space I experienced with my people is now available
                for you to connect.
            </p>
            <p>
                Dialogæ is a first-of-its-kind online volunteering platform
                accessible via the web, app, and tablets.{' '}
                <span className='blue-text'>
                    Dialogæ connects university students with senior home
                    residents through video calls and messages, just as I
                    connected with my people during moments of need
                </span>
                .
            </p>
            <p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '800px',
                        margin: '0 auto',
                    }}
                >
                    {/* <ReactPlayer
                        url='https://www.youtube.com/watch?v=yDSmMNL16bY&t=739shttps://www.youtube.com/watch?v=yDSmMNL16bY&t=739s'
                        controls={true}
                        width='100%'
                        height={400}
                    /> */}

                    <iframe
                        width='660'
                        height='360'
                        src='https://www.youtube.com/embed/yDSmMNL16bY?si=rz-13POdjlFqc-GZ'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerpolicy='strict-origin-when-cross-origin'
                        allowfullscreen
                    ></iframe>
                </div>
            </p>

            <p>
                <strong
                    style={{
                        fontStyle: 'italic',
                        textDecoration: 'underline',
                    }}
                >
                    Mahesh Daswani
                </strong>
            </p>
        </div>
    );
};

export default EngAboutStory;
