import { StreamVideoClient } from '@stream-io/video-react-sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Rating from 'react-rating';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { rateCall } from '../../../api/adapters/appointment';
import { acceptCall, rejectCall } from '../../../api/adapters/call';
import { getNotification } from '../../../api/adapters/notification';
import { getUserProfile } from '../../../api/adapters/profile';
import { reportUser } from '../../../api/adapters/report-user';
import { CommonContext } from '../../../context/CommonState';
import {
    markAllSeen,
    updateNotification,
} from '../../../redux/actions/notification';
import { updateUser } from '../../../redux/actions/user';
import {
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import {
    getCallToken,
    getToken,
    getUserId,
} from '../../../utils/helpers/cookies.helpers';
import {
    formatTimeShort,
    getUserFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../ModalPopUp';
import LangSelector from '../mini-components/LangSelector';
import LoadingButton from '../mini-components/LoadingButton';
import MobileInput from '../mini-components/MobileInput';
import TextArea from '../mini-components/TextArea';
import NotificationToggle from './NotificationToggle';

const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY; // the API key can be found in the "Credentials" section

const MenuButton = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { activeHeader, setActiveHeader } = useContext(CommonContext);

    const handleButtonClick = (e) => {
        setActiveHeader(!activeHeader);
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <button
            className={`btn-menu ${activeHeader ? 'menu-open' : ''}`}
            type='button'
            onClick={handleButtonClick}
        >
            <i className='btn-menu__bars' aria-hidden='true'></i>
        </button>
    );
};

function Header() {
    const {
        setActiveHeader,
        setModalShow,
        setSocketCommon,
        socketCommon,
        directNavigate,
        report,
        setReportPopUp,
        reportUserData,
        setReportUserData,
        reportUserErrors,
        setReportUserErrors,
    } = useContext(CommonContext);

    const [notificationToggle, setNotificationToggle] = useState(false);
    const [caller, setCaller] = useState(null);
    const [callId, setCallId] = useState(null);
    const [callerImage, setCallerImage] = useState(null);
    const [callingPopUp, setCallingPopUp] = useState(false);
    const [isCallingToaster, setIsCallingToaster] = useState(false);
    const [ratingPopup, setRatingPopup] = useState(false);
    const [rating, setRating] = useState(5);
    const [formErrors, setFormErrors] = useState({});
    const [opponentId, setOpponentId] = useState(null);
    const [filteredNotification, setFilteredNotification] = useState([]);
    const { i18n, t } = useTranslation();
    // const [socket, setSocket] = useState(null);

    const navigate = useNavigate();

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isDesk = useMediaQuery({ maxWidth: 992 });

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const notification = useSelector((state) => state.notification);

    // const filteredNotification = notification.filter((notificationItem) => {
    //     Object.values(notificationItem).some((value) => value);
    // });
    // console.log(filteredNotification);

    const pathname = window.location.pathname;
    const phoneUtil = PhoneNumberUtil.getInstance();

    const setInitialState = () => {
        setCallingPopUp(false);
        setIsCallingToaster(false);
        setCaller(null);
        setCallerImage(null);
        setCallId(null);
    };

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BACK_END_URL, {
            auth: { authorization: getToken() },
        });
        setSocketCommon(socket);
        // setSocketCommon(socket);
    }, []);

    useEffect(() => {
        if (socketCommon) {
            socketCommon.connect();
            socketCommon.emit('join-room', getUserId());
            socketCommon.on('show-notification', (data) => {
                dispatch(updateNotification(data));
            });
        }
        return () => {
            if (socketCommon) {
                socketCommon.disconnect();
            }
        };
    }, [socketCommon]);

    useEffect(() => {
        if (directNavigate) {
            navigate(directNavigate);
        }
    }, [directNavigate]);

    const { mutate: mutateAcceptCall } = useMutation({
        mutationFn: (id) => acceptCall(i18n.language?.split('-')[0], id),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/call', { state: { oCallData: response.data } });
        },
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => setInitialState(),
    });

    const { mutate: mutateRejectCall } = useMutation({
        mutationFn: (id) => rejectCall(i18n.language?.split('-')[0], id),
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => setInitialState(),
    });

    const handleRatePopUpClose = () => {
        setRatingPopup(false);
        const oUser = {
            lastCall: {
                id: '',
                name: '',
                image: '',
                seconds: '',
            },
        };
        dispatch(updateUser(oUser));
    };

    const {
        data: userData,
        refetch: refetchUserData,
        error: userDataError,
    } = useQuery({
        queryKey: ['user-profile', i18n.language.split('-')[0], user],
        queryFn: () =>
            getUserProfile(i18n.language.split('-')[0]).then((res) => res.data),
        enabled: false,
    });

    const {
        data: notificationData,
        error: notificationError,
        refetch: refNotification,
    } = useQuery({
        queryKey: ['notification'],
        queryFn: () =>
            getNotification(i18n.language.split('-')[0]).then(
                (res) => res.data
            ),
        enabled: true,
    });

    const { mutate: mutateRateCall } = useMutation({
        mutationFn: () =>
            rateCall(i18n.language.split('-')[0], user.lastCall.id, {
                nRating: rating,
            }),
        onSuccess: (response) => {
            notify('success', response.message);
            setRatingPopup(false);
            const oUser = {
                lastCall: {
                    id: '',
                    name: '',
                    image: '',
                    seconds: '',
                },
            };
            dispatch(updateUser(oUser));
            setRating(5);
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    useEffect(() => {
        if (!user.lastCall.id) return;
        setRatingPopup(true);
    }, [user]);

    const handleShareWithFriends = (_, result) => {
        if (result) notify('success', t('success_share_with_friends'));
        else notify('error', t('error_share_with_friends'));
    };

    useEffect(() => {
        if (userDataError) handleQueryError(userDataError, navigate);
        if (notificationError) handleQueryError(notificationError, navigate);
    }, [userDataError, notificationError]);

    useEffect(() => {
        setActiveHeader(false);
    }, [pathname]);

    useEffect(() => {
        if (userData) {
            const user = {
                id: userData._id,
                firstName: userData.oName.sFirstName,
                middleName: userData.oName.sMiddleName,
                lastName: userData.oName.sLastName,
                accountType: userData.eAccountType,
                email: userData.sEmail,
                contactNumber: userData.sContactNumber,
                gender: userData.eGender,
                dob: userData.dDob,
                bio: userData.sBio,
                profilePicUrl: userData.sProfilePicUrl,
                interests: userData.aInterests,
                university: userData.oUniversity?.sTitle || undefined,
                residency: userData.oResidency?.sTitle || undefined,
                city: userData.oCity.sTitle,
                country: userData.oCountry.sTitle,
                iCurrentRewardTarget: userData.iCurrentRewardTarget,
                isTalkedHoursVisible: userData.bIsTalkedHoursVisible ?? false,
                totalCallSeconds: userData.nTotalCallSeconds,
                totalCalls: userData.nTotalCalls,
                bDisplayTooltips: userData.bDisplayTooltips,
            };

            dispatch(updateUser(user));

            const oUser = {
                id: user.id,
                name: getUserFullName(user),
                image:
                    user.profilePicUrl ||
                    'https://getstream.io/random_svg/?' +
                        new URLSearchParams({
                            id: getUserFullName(user),
                            name: getUserFullName(user),
                        }),
            };

            let myClient;
            socketCommon.on('show-conversation', (data) => {
                if (
                    user.accountType === 'S' &&
                    data.socketId === socketCommon.id
                ) {
                    navigate('/dialog-now-call', {
                        state: { oCallData: data },
                    });
                }
            });

            if (getCallToken()) {
                myClient = new StreamVideoClient({
                    apiKey,
                    user: oUser,
                    token: getCallToken(),
                });

                myClient.on('call.created', (event) => {
                    setOpponentId(
                        event.members[0].user_id !== event.call.created_by.id
                            ? event.members[0].user_id
                            : event.members[1].user_id
                    );
                    if (
                        event.call.custom.call_type === 'dialogae' &&
                        user.accountType === 'R' &&
                        socketCommon.id === event.call.custom.socketId
                    ) {
                        navigate('/dialog-now-call', {
                            state: {
                                oCallData: event.call.custom.oCallData,
                            },
                        });
                    }
                    if (
                        event.call.created_by.id !== user.id &&
                        event.call.custom.call_type !== 'dialogae'
                    ) {
                        setOpponentId(event.call?.created_by?.id);
                        setCallingPopUp(true);
                        setRatingPopup(false);
                        const oMember = event.members.find(
                            (member) => member.user_id !== user.id
                        );
                        setCaller(oMember?.user?.name);
                        setCallerImage(oMember?.user?.image);
                        setCallId(event.call.id);
                    }
                });

                myClient.on('call.rejected', (event) => {
                    if (event.call.created_by.id !== user.id) setInitialState();
                });

                myClient.on('call.ended', (event) => {
                    if (event.call.created_by.id !== user.id) setInitialState();
                });
            }

            return () => {
                if (getCallToken()) {
                    myClient.off('call.created');
                    myClient.off('call.ended');
                    myClient.off('call.rejected');
                }
            };
        }
    }, [userData]);

    useEffect(() => {
        if (getUserId()) {
            refetchUserData();
        }
    }, []);

    useEffect(() => {
        if (notificationData) {
            notificationData.forEach((notificationData) => {
                dispatch(updateNotification(notificationData));
                // if (!notificationData.bIsRead) {
                //     // console.log(notification);
                //     dispatch(updateNotification(notificationData));
                // }
            });
        }
    }, [notificationData, dispatch]);

    const { mutate: mutateReportUser, isLoading: isMutatingReportUser } =
        useMutation({
            mutationFn: (data) => reportUser(i18n.language.split('-')[0], data),
            onSuccess: (response) => {
                notify('success', response.message);

                setReportPopUp(false);
                setReportUserData({
                    country: {
                        name: 'Spain',
                        dialCode: '34',
                        countryCode: 'es',
                        format: '+.. ... ... ...',
                    },
                    reason: '',
                    mobileNumber: '+34',
                });
            },
            onError: (error) => handleApiError(error, t, navigate),
        });

    const handleReportUserReasonChange = (e) => {
        if (e.target.value.length > 350) return;

        setReportUserData((prevReportUserData) => ({
            ...prevReportUserData,
            reason: e.target.value,
        }));
    };

    const handleReportUserMobileChange = (
        _value,
        country,
        _e,
        formattedValue
    ) => {
        setReportUserData((prevReportUserData) => ({
            ...prevReportUserData,
            country,
            mobileNumber: formattedValue,
        }));
    };

    const handleReportUserSubmit = () => {
        const existingErrors = { ...reportUserErrors };

        if (!reportUserData.reason.length)
            existingErrors.reason = t('report_user.error_enter_reason');
        else if (reportUserData.reason.length > 50)
            existingErrors.reason = t('report_user.error_reason_length');
        else existingErrors.reason = '';

        if (
            reportUserData.mobileNumber.replace(
                `+${reportUserData.country.dialCode}`,
                ''
            ).length
        ) {
            try {
                const number = phoneUtil.parseAndKeepRawInput(
                    reportUserData.mobileNumber,
                    reportUserData.country.countryCode
                );

                if (!phoneUtil.isValidNumber(number)) {
                    existingErrors.mobileNumber = t(
                        'report_user.error_invalid_mobile_number'
                    );
                } else {
                    existingErrors.mobileNumber = '';
                }
            } catch (error) {
                existingErrors.mobileNumber = t(
                    'report_user.error_invalid_mobile_number'
                );
            }
        } else {
            existingErrors.mobileNumber = '';
        }

        setReportUserErrors({ ...existingErrors });

        if (new Set(Object.values(existingErrors)).size !== 1) return;

        const data = {
            iUser: opponentId,
            sMessage: reportUserData.reason,
        };

        if (
            reportUserData.mobileNumber.replace(
                `+${reportUserData.country.dialCode}`,
                ''
            ).length
        ) {
            data.sPhoneNumber = reportUserData.mobileNumber;
            data.sCountryCode = reportUserData.country.countryCode;
        }

        mutateReportUser(data);
    };

    const handleReport = () => {
        setReportPopUp(true);
        setRatingPopup(false);
    };

    useEffect(() => {
        const filteredNotificationCount = notification.filter(
            (notificationItem) =>
                // Object.values(notificationItem).some((value) => value)
                notificationItem.bIsRead === false
        );
        setFilteredNotification(filteredNotificationCount);
    }, [notification, notificationData]);

    const updateCount = () => {
        const updatedNotification = notification.map((item, index) => {
            return {
                ...item,
                bIsRead: true,
            };
        });

        dispatch(markAllSeen(updatedNotification));
    };

    return (
        <>
            <div className='dash-header'>
                {!isMobile && <h2>{getUserFullName(user)}</h2>}

                {isMobile && (
                    <Link to={'/'}>
                        <div className='logo mobile-logo'>
                            <img src='/images/icons/logo-vector.svg' alt='' />
                        </div>
                    </Link>
                )}

                <div className='header-action'>
                    <div className='toggle-wrapper'>
                        <button
                            className='notify-btn'
                            onClick={() => {
                                setNotificationToggle((prev) => !prev);
                            }}
                            style={
                                notificationToggle
                                    ? {
                                          zIndex: '2',
                                          position: 'relative',
                                          backgroundColor: '#fff',
                                      }
                                    : {}
                            }
                        >
                            <img src='/images/icons/notify-ic.svg' alt='' />
                            {filteredNotification?.length > 0 && (
                                <span className='notify-count'>
                                    {filteredNotification?.length}
                                </span>
                            )}
                        </button>
                        {notificationToggle ? (
                            <NotificationToggle
                                updateCount={updateCount}
                                refNotification={refNotification}
                                setNotificationToggle={setNotificationToggle}
                                allMessages={notificationData}
                            />
                        ) : (
                            ''
                        )}
                    </div>

                    <div className='language-wrapper'>
                        <LangSelector i18n={i18n} />
                    </div>

                    {!isMobile && (
                        <div className='toggle-wrapper'>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant='unset'
                                    id='dropdown-basic'
                                >
                                    <img
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                '/images/profile-image-placeholder.svg';
                                        }}
                                        src={
                                            user.profilePicUrl ||
                                            '/images/profile-image-placeholder.svg'
                                        }
                                        alt=''
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item to='/profile' as={Link}>
                                        <span>
                                            <img
                                                src='/images/icons/profile-ic1.svg'
                                                alt=''
                                            />{' '}
                                            {t('profile_nav.profile')}
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CopyToClipboard
                                            text={window.location.origin}
                                            onCopy={handleShareWithFriends}
                                        >
                                            <span>
                                                <img
                                                    src='/images/icons/profile-ic2.svg'
                                                    alt=''
                                                />{' '}
                                                {t(
                                                    'profile_nav.share_with_friends'
                                                )}
                                            </span>
                                        </CopyToClipboard>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setModalShow(true)}
                                        className='logout-button'
                                    >
                                        <span>
                                            <img
                                                src='/images/icons/profile-ic3.svg'
                                                alt=''
                                            />{' '}
                                            {t('logout')}
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}

                    {isDesk && <MenuButton />}
                </div>
            </div>

            {/* Calling popUp action  */}
            <ModalPopUp
                show={callingPopUp}
                className='report-modal'
                onHide={() => {
                    setCallingPopUp(false);
                    setIsCallingToaster(true);
                }}
                modalcontent={
                    <div className='report-popup'>
                        <div className='report-dp'>
                            <img src={callerImage} alt='' />
                        </div>
                        <h3 className='popup-title'>{caller}</h3>
                        <h4 className='blue-text'>{t('call.ringing')}</h4>
                        <div className='calling-popup-action'>
                            <button
                                className='simple-btn'
                                onClick={() => mutateRejectCall(callId)}
                            >
                                <img src='/images/call-cut.svg' alt='' />
                            </button>
                            <button
                                className='simple-btn'
                                onClick={() => mutateAcceptCall(callId)}
                            >
                                <img src='/images/calling.svg' alt='' />
                            </button>
                        </div>
                    </div>
                }
            />

            {/* Rating popUP  */}
            <ModalPopUp
                show={ratingPopup}
                className={'report-popup'}
                onHide={handleRatePopUpClose}
                modalcontent={
                    <>
                        <div className='report-popup'>
                            <div className='report-dp'>
                                <img
                                    src={
                                        user.lastCall.image
                                            ? user.lastCall.image
                                            : '/images/profile-image-placeholder.svg'
                                    }
                                    alt=''
                                />
                            </div>
                            <h3 className='popup-title pb-0'>
                                {user.lastCall.name}
                            </h3>
                            <p className='blue-text'>
                                {t('popup_content.call_duration')}{' '}
                                {formatTimeShort(user.lastCall.seconds, t)}
                            </p>
                            <h5 className='pt-2'>
                                {t('popup_content.experience')}
                            </h5>
                            <p className='small grey-text'>
                                {t('popup_content.how_was')}
                            </p>
                            <div className='mt-3 mb-3'>
                                <Rating
                                    emptySymbol={
                                        <img
                                            src='/images/icons/strock_star.png'
                                            className='icon'
                                            alt=''
                                        />
                                    }
                                    fullSymbol={
                                        <img
                                            src='/images/icons/start_fill.png'
                                            className='icon'
                                            alt=''
                                        />
                                    }
                                    initialRating={rating}
                                    quiet={true}
                                    rating={rating}
                                    onClick={(rate) => setRating(rate)}
                                />
                            </div>
                            <div className='popup-action'>
                                <button
                                    className='red-outline primary-btn'
                                    onClick={handleReport}
                                >
                                    {t('popup_content.report')}
                                </button>
                                <button
                                    className='primary-btn'
                                    disabled={!rating}
                                    onClick={mutateRateCall}
                                >
                                    {t('submit_btn')}
                                </button>
                            </div>
                        </div>
                    </>
                }
                isCloseIcon={false}
                isBackDrop={true}
            />

            {/* Report User PopUp */}

            <ModalPopUp
                show={report}
                className='report-modal'
                onHide={() => {
                    setReportPopUp(false);
                    setReportUserData({
                        country: {
                            name: 'Spain',
                            dialCode: '34',
                            countryCode: 'es',
                            format: '+.. ... ... ...',
                        },
                        reason: '',
                        mobileNumber: '+34',
                    });
                    setReportUserErrors({
                        reason: '',
                        mobileNumber: '',
                    });
                }}
                modalcontent={
                    <div className='report-popup'>
                        <div className='report-dp'>
                            <img
                                src={
                                    user.lastCall.image
                                        ? user.lastCall.image
                                        : '/images/profile-image-placeholder.svg'
                                }
                                alt=''
                            />
                        </div>
                        <h3 className='popup-title'>{user.lastCall.name}</h3>
                        <h4 className='blue-text'>{t('chat.report_user')}</h4>
                        <p className='sm'>{t('chat.please_share')}</p>

                        <TextArea
                            placeholder={`${t('chat.report_reason')}...`}
                            value={reportUserData.reason}
                            handleOnChange={handleReportUserReasonChange}
                            charactersCount={reportUserData.reason.length}
                            errorMessage={reportUserErrors.reason}
                            maxCharacter={350}
                        />
                        <div className='text-start'>
                            <div className='country-input'>
                                <p>{t('mobile_label')}</p>
                                <MobileInput
                                    country={reportUserData.country.countryCode}
                                    value={reportUserData.mobileNumber}
                                    handleMobileChange={
                                        handleReportUserMobileChange
                                    }
                                    errorMessage={reportUserErrors.mobileNumber}
                                />
                            </div>
                        </div>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => handleReportUserSubmit()}
                                disabled={isMutatingReportUser}
                            >
                                {isMutatingReportUser ? (
                                    <LoadingButton color={true} />
                                ) : (
                                    t('chat.submit')
                                )}
                            </button>
                        </div>
                    </div>
                }
            />

            {/* Calling Toaster PopUp */}
            <div
                className={`calling-toaster ${
                    isCallingToaster ? 'calling-toaster-show' : ''
                }`}
            >
                <div className='calling-toaster-inner'>
                    <div className='name-dp'>
                        <div className='calling-toaster-dp'>
                            <img src={callerImage} alt='' />
                        </div>
                        <div className='calling-toaster-content'>
                            <h5 className='calling-toaster-title'>{caller}</h5>
                            <p className='calling-toaster-subtitle'>
                                {t('call.ringing')}
                            </p>
                        </div>
                    </div>
                    <div className='calling-toaster-action'>
                        <button
                            className='simple-btn'
                            onClick={() => mutateRejectCall(callId)}
                        >
                            <img src='/images/call-cut.svg' alt='' />
                        </button>
                        <button
                            className='simple-btn'
                            onClick={() => mutateAcceptCall(callId)}
                        >
                            <img src='/images/calling.svg' alt='' />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
