import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    getRescheduleAppointment,
    reScheduleAppointment,
} from '../../../api/adapters/appointment';
import {
    getAvailability,
    setAvailability,
} from '../../../api/adapters/availability';
import {
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import {
    formatTalkedHours,
    getFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Categories from '../../components/common-component/Categories';
import TimeSlots from '../../components/common-component/TimeSlots';
import TitleHeader from '../../components/common-component/TitleHeader';
import DateSelector from '../../components/mini-components/DateSelector';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';
import LoadingButton from '../../components/mini-components/LoadingButton';
import TextArea from '../../components/mini-components/TextArea';

function RescheduleCall(props) {
    const { t, i18n } = useTranslation();
    const { interestHeading = false } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(null);
    const [slots, setSlots] = useState([]);
    const [availabilityModalShow, setAvailabilityModalShow] = useState(false);
    const [isAvailabilityRefetched, setIsAvailabilityRefetched] =
        useState(false);

    const [formErrors, setFormErrors] = useState({});

    const [reScheduleCallDetails, setReScheduleCallDetails] = useState({
        interestMatched: [],
        city: '',
        country: '',
        name: '',
        bio: '',
        chatMessage: '',
        profilePic: '',
        appointmentId: '',
        userId: '',
        rescheduleCount: '',
    });

    const {
        data: viewRescheduleAppointment,
        refetch: refetchRescheduledAppointments,
    } = useQuery({
        queryKey: ['view-appointment-reschedule-detail', id],
        queryFn: () =>
            getRescheduleAppointment(i18n.language.split('-')[0], id).then(
                (res) => {
                    setReScheduleCallDetails({
                        interestMatched: res?.data?.aMatchedInterests,
                        city: res?.data?.sCity,
                        country: res?.data?.sCountry,
                        name: res?.data?.oName,
                        bio: res?.data?.sBio,
                        chatMessage: res?.data?.sMessage,
                        profilePic: res?.data?.sProfilePicUrl,
                        appointmentId: res?.data?._id,
                        userId: res?.data?.iUserId,
                        rescheduleCount: res?.data?.nRescheduleCount,
                        totalCallSeconds: res?.data?.nTotalCallSeconds,
                    });
                    return res.data;
                }
            ),
        enabled: false,
    });

    useEffect(() => {
        refetchRescheduledAppointments();
    }, [id]);

    const {
        data: availabilityData,
        isLoading: isLoadingAvailabilityData,
        refetch: refetchAvailability,
        error: availabilityError,
    } = useQuery({
        queryKey: ['availabilities', getUserId(), i18n.language.split('-')[0]],
        queryFn: () =>
            getAvailability(
                i18n.language.split('-')[0],
                getUserId(),
                false,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
    });

    const {
        data: dialoguerAvailabilityData,
        isFetching: isFetchingDialoguerAvailabilityData,
        refetch: refetchDialoguerAvailabilityData,
        error: dialoguerAvailabilityError,
    } = useQuery({
        queryKey: [
            'availabilities',
            reScheduleCallDetails?.userId,
            i18n.language.split('-')[0],
        ],
        queryFn: () =>
            getAvailability(
                i18n.language.split('-')[0],
                reScheduleCallDetails?.userId,
                false,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
        enabled: false,
    });

    const { mutate: mutateSetAvailability, isLoading: isAvailabilityMutating } =
        useMutation({
            mutationFn: (data) =>
                setAvailability(
                    i18n.language.split('-')[0],
                    data,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            onSuccess: (response) => {
                notify('success', response.message);
                refetchAvailability();
                setIsAvailabilityRefetched(true);
                setAvailabilityModalShow(false);
            },
            onError: (error) =>
                handleApiError(error, t, navigate, setFormErrors),
        });

    const {
        mutate: mutateReScheduleCall,
        isLoading: isReScheduleCallMutating,
    } = useMutation({
        mutationFn: (data) =>
            reScheduleAppointment(
                i18n.language.split('-')[0],
                reScheduleCallDetails?.appointmentId,
                data,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ),
        onSuccess: (response) => {
            notify('success', response.message);
            navigate('/appointments/schedule');
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    useEffect(() => {
        if (isAvailabilityRefetched) handleSubmit();
    }, [availabilityData, isAvailabilityRefetched]);

    useEffect(() => {
        if (availabilityError) handleQueryError(availabilityError, navigate);
    }, [availabilityError]);

    useEffect(() => {
        if (viewRescheduleAppointment?.iUserId)
            refetchDialoguerAvailabilityData();
    }, [viewRescheduleAppointment?.iUserId]);

    useEffect(() => {
        const availabilityArray = Array.isArray(dialoguerAvailabilityData)
            ? dialoguerAvailabilityData
            : dialoguerAvailabilityData?.data || [];
        if (availabilityArray?.length)
            setSelectedDate(moment(availabilityArray[0].dDate).format());
    }, [dialoguerAvailabilityData]);
    useEffect(() => {
        const errors = {};
        const availabilityArray = Array.isArray(dialoguerAvailabilityData)
            ? dialoguerAvailabilityData
            : dialoguerAvailabilityData?.data || [];
        if (availabilityArray?.length === 0) {
            errors.dDate = t('appointments.errors.no_available_set', {
                name: getFullName(reScheduleCallDetails?.name),
            });
        } else errors.dDate = '';

        setFormErrors(errors);
    }, [dialoguerAvailabilityData]);

    useEffect(() => {
        const availabilityArray = Array.isArray(dialoguerAvailabilityData)
            ? dialoguerAvailabilityData
            : dialoguerAvailabilityData?.data || [];
        const index = availabilityArray?.findIndex((item) => {
            return moment.utc(item.dDate).isSame(moment.utc(selectedDate));
        });

        const updatedSlots = availabilityArray?.[index]?.aSlots?.map((item) => {
            return {
                from: moment(item.dStartTime).format('HH:mm'),
                to: moment(item.dEndTime).format('HH:mm'),
                availabilityId: item.iAvailabilityId,
                id: item._id,
                isSelected: false,
            };
        });

        setSlots(updatedSlots);
    }, [selectedDate, dialoguerAvailabilityData]);

    const handleMessageChange = (e) => {
        // setMessage(e.target.value);
        const inputValue = e.target.value;
        const charactersCount = inputValue.length;

        if (charactersCount <= 100)
            setReScheduleCallDetails((prev) => ({
                ...prev,
                chatMessage: inputValue,
            }));
    };

    const handleSetAvailability = () => {
        let selectedSlots = slots
            .filter((item) => item.isSelected)
            .map((item) => ({ sStartTime: item.from, sEndTime: item.to }));

        const index = availabilityData?.data?.findIndex((item) =>
            moment.utc(item.dDate).isSame(moment.utc(selectedDate))
        );

        if (index >= 0) {
            const additionalSlots = availabilityData?.data[index].aSlots.map(
                (item) => {
                    return {
                        sStartTime: moment(item.dStartTime).format('HH:mm'),
                        sEndTime: moment(item.dEndTime).format('HH:mm'),
                    };
                }
            );

            selectedSlots = [...selectedSlots, ...additionalSlots];

            selectedSlots = selectedSlots.filter(
                (slot, index, self) =>
                    index ===
                    self.findIndex(
                        (slot2) =>
                            slot2.sStartTime === slot.sStartTime &&
                            slot2.sEndTime === slot.sEndTime
                    )
            );
        }

        const payload = {
            dStartDate: moment(selectedDate).format('YYYY-MM-DD'),
            dEndDate: moment(selectedDate).format('YYYY-MM-DD'),
            aSlots: selectedSlots,
            sType: 'once',
            nAlertBefore: 5,
        };
        mutateSetAvailability(payload);
    };

    const handleSubmit = () => {
        const selectedSlots = slots.filter((item) => item.isSelected);

        const errors = formErrors;

        // Validate Date
        if (!selectedDate)
            errors.dDate = t('appointments.errors.date_required');
        else errors.dDate = '';

        // Validate Slots
        if (!selectedSlots.length)
            errors.aSelectedSlot = t('appointments.errors.slots_required');
        else errors.aSelectedSlot = '';

        // Validate Message
        if (reScheduleCallDetails?.chatMessage.length > 50)
            errors.sMessage = t('appointments.errors.message_max_length');
        else errors.sMessage = '';

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;

        const index = availabilityData?.data?.findIndex((item) => {
            return (
                moment(item.dDate).format('DD-MM-YYYY') ===
                moment(selectedDate).format('DD-MM-YYYY')
            );
        });
        try {
            if (index >= 0) {
                const aSlots = availabilityData?.data[index].aSlots.filter(
                    (item) => {
                        const slotTime = moment(item.dStartTime).format(
                            'HH:mm'
                        );
                        const selectedSlot = selectedSlots?.find(
                            (slot) => slot.from === slotTime
                        );
                        if (selectedSlot?.bHasAppointment)
                            throw new Error(
                                t('appointments.errors.slot_not_available')
                            );
                        return selectedSlot;
                    }
                );
                if (aSlots?.length !== selectedSlots?.length) {
                    setAvailabilityModalShow(true);
                    return;
                }
            } else {
                setAvailabilityModalShow(true);
                return;
            }
        } catch (error) {
            notify('info', error.message);
        }

        const payload = {
            dDate: moment(selectedDate).format('YYYY-MM-DD'),
            aSelectedSlots: selectedSlots.map((item) => {
                return {
                    availabilityId: item.availabilityId,
                    id: item.id,
                };
            }),
            sMessage: reScheduleCallDetails?.chatMessage,
        };
        mutateReScheduleCall(payload);
    };

    const handleBackbtn = () => {
        navigate(-1);
    };

    return (
        <>
            <BreadcrumbAction
                lastPageLink='/appointments/schedule'
                lastPage={t('appointments.appointments')}
                currentPage={t('appointments.reschedule')}
            />
            <div className='reschedule-call content-wrapper'>
                <TitleHeader
                    isBackBtn={handleBackbtn}
                    title={t('appointments.reschedule_call')}
                    btn_title={t('home.submit')}
                    btnClick={handleSubmit}
                    hideBtn={false}
                />
                <div className='schedule-call-data'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='user-dp'>
                                <img
                                    src={`${
                                        reScheduleCallDetails?.profilePic ||
                                        '/images/profile-image-placeholder.svg'
                                    }`}
                                    alt=''
                                />
                            </div>
                            <h2 className='name'>
                                {getFullName(reScheduleCallDetails?.name)}
                            </h2>
                            <ul className='location'>
                                <li>
                                    <img
                                        src='/images/location-ic.svg'
                                        alt='location'
                                    />
                                    <span>
                                        {reScheduleCallDetails?.city}
                                        {', '}
                                        {reScheduleCallDetails?.country}
                                    </span>
                                </li>

                                {reScheduleCallDetails?.totalCallSeconds !==
                                    undefined &&
                                reScheduleCallDetails?.totalCallSeconds !==
                                    null ? (
                                    <li>
                                        <img
                                            src='/images/tele-ic.svg'
                                            alt='tele'
                                        />
                                        <span>
                                            {t('talked_for')}{' '}
                                            {formatTalkedHours(
                                                i18n.language.split('-')[0],
                                                reScheduleCallDetails?.totalCallSeconds
                                            )}
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                            {reScheduleCallDetails?.bio && (
                                <div className='bio'>
                                    <p className='lg'>{t('home.bio')}</p>
                                    <p className='bio-content'>
                                        {reScheduleCallDetails?.bio}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='col-md-8'>
                            {interestHeading && (
                                <p className='lg interest-heading'>Interests</p>
                            )}
                            {reScheduleCallDetails?.interestMatched?.length ? (
                                <div className='matched'>
                                    <Categories
                                        // conversation={[...Array(4).keys()]}
                                        interestItems={reScheduleCallDetails?.interestMatched?.reduce(
                                            (acc, cur) => {
                                                const categoryInterestItems =
                                                    cur.interestCategory.aInterestItems.map(
                                                        (item) => ({
                                                            sTitle: item.sTitle,
                                                            sIconUrl:
                                                                item.sIconUrl,
                                                        })
                                                    );

                                                acc.push(
                                                    ...categoryInterestItems
                                                );
                                                return acc;
                                            },
                                            []
                                        )}
                                        isSelectable={false}
                                        title={t('home.matched_interests')}
                                    />
                                </div>
                            ) : null}
                            <div className='grey-bg'>
                                <h3 className='pt-24'>
                                    {t('home.book_appointment')}
                                </h3>
                                <div className='row form-space'>
                                    <div className='col-sm-6'>
                                        <DateSelector
                                            value={selectedDate}
                                            label={t('home.date')}
                                            handleDateChange={(date) => {
                                                setSelectedDate(date);
                                            }}
                                            startYear={new Date().getFullYear()}
                                            endYear={
                                                new Date().getFullYear() + 2
                                            }
                                            placeholder={t('home.select_date')}
                                            includeDates={
                                                // dialoguerAvailabilityData?.map(
                                                //     (item) =>
                                                //         new Date(item.dDate)
                                                // ) || []
                                                (Array.isArray(
                                                    dialoguerAvailabilityData
                                                )
                                                    ? dialoguerAvailabilityData
                                                    : dialoguerAvailabilityData?.data ||
                                                      []
                                                ).map(
                                                    (item) =>
                                                        new Date(item.dDate)
                                                )
                                            }
                                            errorMessage={formErrors?.dDate}
                                        />
                                    </div>
                                    {slots?.length ? (
                                        <div className='col-12'>
                                            <TimeSlots
                                                slotLabel={t(
                                                    'home.select_time'
                                                )}
                                                colorLabel='#666'
                                                slots={slots}
                                                setSlots={setSlots}
                                            />
                                            {formErrors.aSelectedSlot ? (
                                                <FormErrorLabel
                                                    message={
                                                        formErrors.aSelectedSlot
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    ) : null}

                                    <div className='col-12'>
                                        <TextArea
                                            placeholder={t(
                                                'home.text_placeholder'
                                            )}
                                            label={t('home.chat_message')}
                                            chatIcon={true}
                                            value={
                                                reScheduleCallDetails?.chatMessage
                                            }
                                            charactersCount={
                                                reScheduleCallDetails
                                                    ?.chatMessage?.length
                                            }
                                            handleOnChange={handleMessageChange}
                                            errorMessage={formErrors.sMessage}
                                        />
                                    </div>

                                    <div className='col-12'>
                                        <div className='error-msg edit-profile-error mt-0 justify-content-center'>
                                            <img
                                                src='/images/icons/error-ic.svg'
                                                alt='error-ic'
                                            />
                                            <p className='error'>
                                                {t('appointments.you_can', {
                                                    attemptsLeft:
                                                        2 -
                                                        reScheduleCallDetails?.rescheduleCount,
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className='primary-btn mt-4'
                                    onClick={handleSubmit}
                                    disabled={isReScheduleCallMutating}
                                >
                                    {isReScheduleCallMutating ? (
                                        <LoadingButton color='White' />
                                    ) : (
                                        t('home.submit')
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalPopUp
                    show={availabilityModalShow}
                    onHide={() => setAvailabilityModalShow(false)}
                    modalcontent={
                        <>
                            <img src='/images/icons/warn-ic.svg' alt='' />

                            <h3 className='popup-title'>
                                {t('home.missing_availability')}
                            </h3>

                            <p className='sm'>{t('home.you_have_not')}</p>

                            <div className='popup-action'>
                                <button
                                    className='primary-btn'
                                    onClick={handleSetAvailability}
                                >
                                    {isAvailabilityMutating ? (
                                        <LoadingButton color='White' />
                                    ) : (
                                        t('instruction.set_availability')
                                    )}
                                </button>
                                <button
                                    className='simple-btn'
                                    onClick={() => {
                                        setAvailabilityModalShow(false);
                                    }}
                                >
                                    {t('home.change_slots')}
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
}

export default RescheduleCall;
