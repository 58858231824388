import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../../api/adapters/auth';
import { formatPageTitle } from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import { isValidEmail } from '../../../utils/validators/common.validators';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';
import InputField from '../../components/mini-components/InputField';
import LangSelector from '../../components/mini-components/LangSelector';
import LoadingButton from '../../components/mini-components/LoadingButton';
import ModalPopUp from '../../components/ModalPopUp';

function RightContent(props) {
    const { i18n, t, setShowConfirmModal } = props;

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: (data) =>
            forgotPassword(i18n.language?.split('-')[0], data),
        onSuccess: (response) => {
            notify('success', response.message);

            // navigate('/sign-in');
            setShowConfirmModal(true);
        },
        onError: (error) => {
            if (error.status === 422) {
                setEmailError(
                    error.message?.response?.data?.data?.errors?.[0]?.msg
                );
            } else {
                notify(
                    'error',
                    error.message || t('common.errors.something_went_wrong')
                );
            }
        },
    });

    const handleInputChange = (e) => setEmail(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) return setEmailError(t('sign_up.error_enter_email'));
        else if (!isValidEmail(email))
            return setEmailError(t('sign_up.error_invalid_email'));
        else setEmailError('');

        mutate({ sEmail: email });
    };

    return (
        <div className='right-screen'>
            <div className='language-selector'>
                <LangSelector i18n={i18n} />

                <div className='account-setup-wrapper'>
                    <h1 className='text-center'>
                        {t('account_setup.forgot_password')}
                    </h1>

                    <Form className='login-form'>
                        <p className='text-center'>
                            {t('account_setup.please_enter')}
                        </p>
                        <div className='row form-space'>
                            <div className='col-12'>
                                <InputField
                                    label={t('field_name.email_id')}
                                    placeholder={t(
                                        'field_name.email_id_placeholder'
                                    )}
                                    type='email'
                                    value={email}
                                    errorMsg={emailError}
                                    handleOnChange={handleInputChange}
                                />

                                <div className='action-btn'>
                                    <button
                                        className='primary-btn w-100'
                                        onClick={handleSubmit}
                                        disabled={isMutating}
                                    >
                                        {isMutating ? (
                                            <LoadingButton color='White' />
                                        ) : (
                                            t('submit_btn')
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
function ForgotPassword() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        document.title = formatPageTitle(t('auth.title_forgot_password'));
    }, [i18n.language]);

    const handleConfirmModalHide = () => {
        setShowConfirmModal(false);
        navigate('/sign-in');
    };

    const handleContinue = () => {
        setShowConfirmModal(false);
        navigate('/sign-in');
    };

    return (
        <>
            <div className='account-setup'>
                <LeftTextAnimation />

                <RightContent
                    i18n={i18n}
                    t={t}
                    setShowConfirmModal={setShowConfirmModal}
                />
            </div>
            <ModalPopUp
                show={showConfirmModal}
                onHide={handleConfirmModalHide}
                isBackDrop={true}
                modalcontent={
                    <>
                        <img src='/images/icons/Blue_Tick.svg' alt='' />
                        <h3 className='popup-title'>
                            {t('forgot_password_modal.title')}
                            {/* Cambios no guardados */}
                        </h3>
                        <p className='sm'>
                            {/* Los cambios que realizaste no sean han guardado.
                                        ¿Estás seguro de que quieres salir? */}
                            {t('forgot_password_modal.desc')}
                        </p>
                        <div className='popup-action'>
                            {/* <button
                                className='primary-btn'
                                onClick={handleDiscard}
                            >
                                Descartar
                                {t('profile_update_pop_up.dismiss')}
                            </button> */}
                            <button
                                className='primary-btn'
                                onClick={handleContinue}
                            >
                                {/* Continuar */}
                                {t('profile_update_pop_up.stay')}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

export default ForgotPassword;
