import deLocale from '@fullcalendar/core/locales/de';
import enLocale from '@fullcalendar/core/locales/en-au.js';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    deleteAvailability,
    deleteSlot,
    getAvailability,
    setAvailability,
} from '../../../api/adapters/availability';
import {
    formatPageTitle,
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers.js';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction.jsx';
import TimeSelector from '../../components/dashboard/TimeSelector';
import DateSelector from '../../components/mini-components/DateSelector';
import DropDownSelector from '../../components/mini-components/DropDownSelector';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';
import LoadingButton from '../../components/mini-components/LoadingButton';

function CustomEvent({ eventInfo }) {
    const { t, i18n } = useTranslation();

    const { title, extendedProps } = eventInfo.event;
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});

    const [modalShow, setModalShow] = useState(false);

    const { mutate: mutateDeleteSlots, isLoading: isMutating } = useMutation({
        mutationFn: (data) =>
            deleteSlot(i18n.language.split('-')[0], data.availabilityId, {
                iSlotId: data.slotId,
            }),
        onSuccess: (response) => {
            setModalShow(false);
            extendedProps.refetchAvailability();
            notify('success', response.message);
        },

        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    return (
        <>
            <div
                className={`event-card ${
                    extendedProps.hasAppointment && 'accepted'
                }`}
            >
                <span>{title}</span>
                {new Date().getTime() <
                    new Date(extendedProps.date).getTime() &&
                !extendedProps.hasAppointment ? (
                    <div className='event-action'>
                        <button
                            className='simple-btn'
                            onClick={(e) => {
                                extendedProps.setSelectedDate(
                                    extendedProps.date
                                );
                                extendedProps.setModalShow(true);
                            }}
                        >
                            <img src='/images/edit.svg' alt='' />
                        </button>
                        <button
                            className='simple-btn'
                            onClick={(e) => {
                                setModalShow(true);
                            }}
                        >
                            <img src='/images/delete.svg' alt='' />
                        </button>
                    </div>
                ) : null}
            </div>

            <ModalPopUp
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                }}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {t('popup_content.are_you')}
                        </h3>

                        <p className='sm'>
                            {t('popup_content.are_you_desc_slot')}
                        </p>

                        <div className='popup-action'>
                            <button
                                onClick={() => {
                                    setModalShow(false);
                                }}
                                className='primary-btn '
                            >
                                {t('popup_content.no_dont')}
                            </button>

                            <button
                                className='simple-btn'
                                onClick={() => {
                                    mutateDeleteSlots(extendedProps);
                                }}
                                disabled={isMutating}
                            >
                                {isMutating ? (
                                    <LoadingButton />
                                ) : (
                                    t('popup_content.yes_delete')
                                )}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

function CustomCalendar() {
    const { t, i18n } = useTranslation();

    const LOCALE_MAP = { en: enLocale, es: esLocale, de: deLocale };

    const repeatTypes = [
        { label: 'availability.only_today', value: 'once', id: 'one' },
        { label: 'availability.daily', value: 'daily', id: 'two' },
        { label: 'availability.weekly', value: 'weekly', id: 'three' },
        { label: 'availability.monthly', value: 'monthly', id: 'four' },
        { label: 'availability.multiple', value: 'multiple', id: 'five' },
    ];

    // Day symbols is in spenish
    const weekDays = [
        {
            name: 'Monday',
            symbol: t('availability.days_symbol.mon'),
            value: 1,
            short: 'mon',
        },
        {
            name: 'Tuesday',
            symbol: t('availability.days_symbol.tue'),
            value: 2,
            short: 'tue',
        },
        {
            name: 'Wednesday',
            symbol: t('availability.days_symbol.wed'),
            value: 3,
            short: 'wed',
        },
        {
            name: 'Thursday',
            symbol: t('availability.days_symbol.thu'),
            value: 4,
            short: 'thu',
        },
        {
            name: 'Friday',
            symbol: t('availability.days_symbol.fri'),
            value: 5,
            short: 'fri',
        },
        {
            name: 'Saturday',
            symbol: t('availability.days_symbol.sat'),
            value: 6,
            short: 'sat',
        },
        {
            name: 'Sunday',
            symbol: t('availability.days_symbol.sun'),
            value: 0,
            short: 'sun',
        },
    ];

    const frequencyOptions = [
        { label: t('availability.week'), value: 'week' },
        { label: t('availability.month'), value: 'month' },
        { label: t('availability.year'), value: 'year' },
    ];

    const repeatAfterOptions = [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
    ];

    const alertOptions = [
        { label: t('availability.notify_event'), value: '0' },
        { label: t('availability.notify_5m'), value: '5' },
        { label: t('availability.notify_15m'), value: '15' },
        { label: t('availability.notify_30m'), value: '30' },
        { label: t('availability.notify_1h'), value: '60' },
    ];

    const allTimeSlots = [
        { from: '6:00', to: '6:30', isSelected: false },
        { from: '6:30', to: '7:00', isSelected: false },
        { from: '7:00', to: '7:30', isSelected: false },
        { from: '7:30', to: '8:00', isSelected: false },
        { from: '8:00', to: '8:30', isSelected: false },
        { from: '8:30', to: '9:00', isSelected: false },
        { from: '9:00', to: '9:30', isSelected: false },
        { from: '9:30', to: '10:00', isSelected: false },
        { from: '10:00', to: '10:30', isSelected: false },
        { from: '10:30', to: '11:00', isSelected: false },
        { from: '11:00', to: '11:30', isSelected: false },
        { from: '11:30', to: '12:00', isSelected: false },
        { from: '12:00', to: '12:30', isSelected: false },
        { from: '12:30', to: '13:00', isSelected: false },
        { from: '13:00', to: '13:30', isSelected: false },
        { from: '13:30', to: '14:00', isSelected: false },
        { from: '14:00', to: '14:30', isSelected: false },
        { from: '14:30', to: '15:00', isSelected: false },
        { from: '15:00', to: '15:30', isSelected: false },
        { from: '15:30', to: '16:00', isSelected: false },
        { from: '16:00', to: '16:30', isSelected: false },
        { from: '16:30', to: '17:00', isSelected: false },
        { from: '17:00', to: '17:30', isSelected: false },
        { from: '17:30', to: '18:00', isSelected: false },
        { from: '18:00', to: '18:30', isSelected: false },
        { from: '18:30', to: '19:00', isSelected: false },
        { from: '19:00', to: '19:30', isSelected: false },
        { from: '19:30', to: '20:00', isSelected: false },
        { from: '20:00', to: '20:30', isSelected: false },
        { from: '20:30', to: '21:00', isSelected: false },
        { from: '21:00', to: '21:30', isSelected: false },
        { from: '21:30', to: '22:00', isSelected: false },
        { from: '22:00', to: '22:30', isSelected: false },
        { from: '22:30', to: '23:00', isSelected: false },
        { from: '23:00', to: '23:30', isSelected: false },
        { from: '23:30', to: '0:00', isSelected: false },
        { from: '0:00', to: '0:30', isSelected: false },
        { from: '0:30', to: '1:00', isSelected: false },
        { from: '1:00', to: '1:30', isSelected: false },
        { from: '1:30', to: '2:00', isSelected: false },
        { from: '2:00', to: '2:30', isSelected: false },
        { from: '2:30', to: '3:00', isSelected: false },
        { from: '3:00', to: '3:30', isSelected: false },
        { from: '3:30', to: '4:00', isSelected: false },
        { from: '4:00', to: '4:30', isSelected: false },
        { from: '4:30', to: '5:00', isSelected: false },
        { from: '5:00', to: '5:30', isSelected: false },
        { from: '5:30', to: '6:00', isSelected: false },
    ];

    const [events, setEvents] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [repeatType, setRepeatType] = useState('once');
    const [repeatAfter, setRepeatAfter] = useState(1);
    const [selectedDays, setSelectedDays] = useState([]);
    const [repeatFrequency, setRepeatFrequency] = useState('week');
    const [alertTime, setAlertTime] = useState('5');
    const [currentView, setCurrentView] = useState('dayGridMonth');
    const [showDelete, setShowDelete] = useState(false);
    const [maxSlots, setMaxSlots] = useState(48);
    const [allSlots, setAllSlots] = useState(allTimeSlots);

    const [formErrors, setFormErrors] = useState({});
    const [hasError, setHasError] = useState(false);

    const navigate = useNavigate();

    const {
        data: availabilities,
        isLoading,
        refetch: refetchAvailability,
        error: availabilitiesError,
    } = useQuery({
        queryKey: ['availabilities', getUserId(), i18n.language.split('-')[0]],
        queryFn: () =>
            getAvailability(
                i18n.language.split('-')[0],
                getUserId(),
                true,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
    });

    const { mutate: mutateSetAvailability, isLoading: isMutating } =
        useMutation({
            mutationFn: (data) =>
                setAvailability(
                    i18n.language.split('-')[0],
                    data,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            onSuccess: (response) => {
                notify('success', response.message);
                refetchAvailability();
                setModalShow(false);
            },
            onError: (error) =>
                handleApiError(error, t, navigate, setFormErrors),
        });

    const { mutate: mutateDeleteAvailability, isLoading: isDeleting } =
        useMutation({
            mutationFn: (data) =>
                deleteAvailability(
                    i18n.language.split('-')[0],
                    { dDate: moment(selectedDate).format('YYYY-MM-DD') },
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            onSuccess: (response) => {
                refetchAvailability();
                setDeleteModalShow(false);
                notify('success', response.message);
            },
            onError: (error) =>
                handleApiError(error, t, navigate, setFormErrors),
        });

    useEffect(() => {
        if (availabilitiesError)
            handleQueryError(availabilitiesError, navigate);
    }, [availabilitiesError]);

    useEffect(() => {
        if (availabilities?.data?.length) {
            const chosenSlots = availabilities?.data.flatMap((availability) =>
                availability.aSlots.map((slot) => ({ ...slot }))
            );
            const chosenEvents = chosenSlots.map((slot) => ({
                title:
                    moment(slot.dStartTime).format('HH:mm') +
                    ' - ' +
                    moment(slot.dEndTime).format('HH:mm'),
                start: moment(slot.dStartTime).format('YYYY-MM-DDTHH:mm:ss'),
                end: moment(slot.dEndTime).format('YYYY-MM-DDTHH:mm:ss'),
                allDay: currentView === 'dayGridMonth',
                extendedProps: {
                    availabilityId: slot.iAvailabilityId,
                    slotId: slot._id,
                    hasAppointment: slot.bHasAppointment,
                    date: new Date(slot.dStartTime),
                    refetchAvailability,
                    setModalShow,
                    setSelectedDate,
                },
            }));
            setEvents(chosenEvents);
        }
    }, [availabilities?.data, currentView]);

    useEffect(() => {
        let filteredSlots = [];
        if (availabilities?.data?.length) {
            const availabilityOfDate = availabilities?.data.filter(
                (obj) =>
                    moment(obj.dDate).format('DD-MM-YYYY') ===
                    moment(selectedDate).format('DD-MM-YYYY')
            );
            filteredSlots = availabilityOfDate[0]?.aSlots?.filter((slot) =>
                moment(slot.dStartTime).isAfter(moment())
            );
            setSelectedSlots(filteredSlots);
            filteredSlots?.length ? setShowDelete(true) : setShowDelete(false);
            filteredSlots?.length
                ? setAlertTime(
                      String(
                          (moment(filteredSlots[0].dStartTime).unix() -
                              moment(filteredSlots[0].dAlertTime).unix()) /
                              60
                      )
                  )
                : setAlertTime('5');
        }
        if (!filteredSlots?.length && selectedDate) handleEmptySlots();
    }, [selectedDate, availabilities?.data]);

    useEffect(() => {
        setEndDate(null);
        setRepeatType('once');
        setRepeatAfter(1);
        setSelectedDays([]);
        setRepeatFrequency('week');
        setFormErrors({});
        const daySelected = weekDays.find(
            (day) => day.value === new Date(selectedDate).getDay()
        );
        setSelectedDay(daySelected.short);
        if (
            moment(selectedDate).startOf('day').isSame(moment().startOf('day'))
        ) {
            const [currentHour, currentMin] = moment()
                .format('HH:mm')
                .split(':');

            const maximumSlots =
                (23 - currentHour) * 2 + (currentMin < 30 ? 1 : 0);
            setMaxSlots(maximumSlots);
        } else setMaxSlots(48);
    }, [selectedDate]);

    useEffect(() => {
        setAllSlots(() => {
            const updatedSlots = [...allTimeSlots];
            selectedSlots?.map((item) => {
                const startTimeSlot = moment(item.dStartTime).format('H:mm');
                const selectedSlotIndex = updatedSlots.findIndex(
                    (slots) => slots.from === startTimeSlot
                );
                updatedSlots[selectedSlotIndex].isSelected = true;
                return startTimeSlot;
            });
            return [...updatedSlots];
        });
    }, [selectedSlots]);

    useEffect(() => {
        document.title = formatPageTitle(t('availability.availability'));
    }, [i18n.language]);

    const handleDateClick = (info) => {
        const { date } = info;
        if (date < new Date().setHours(0, 0, 0, 0))
            return notify('error', t('availability.errors.past_start_date'));
        setSelectedDate(date);
        setModalShow(true);
    };

    const handleDateChange = (date) => {
        const errors = formErrors;
        if (date < new Date().setHours(0, 0, 0, 0)) {
            errors.dStartDate = t('availability.errors.past_start_date');
            setFormErrors({ ...errors });
            throw new Error('Selected date is in the past');
        }
        errors.dStartDate = '';
        setFormErrors({ ...errors });
        setSelectedDate(date);
    };

    const handleEndDateChange = (date) => {
        const errors = formErrors;
        if (new Date(selectedDate).setHours(0, 0, 0, 0) > date) {
            errors.dEndDate = t(
                'availability.errors.end_date_before_start_date'
            );
            setFormErrors({ ...errors });
            throw new Error('End date cannot be before start date');
        }
        errors.dEndDate = '';
        setFormErrors({ ...errors });
        setEndDate(date);
    };

    const handleEmptySlots = () => {
        const currentDate = new Date();
        if (
            selectedDate.setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0)
        ) {
            currentDate.setMinutes(
                currentDate.getMinutes() +
                    (30 - (currentDate.getMinutes() % 30))
            );
            setSelectedSlots([
                {
                    dStartTime: currentDate,
                    dEndTime: new Date(currentDate).setMinutes(
                        currentDate.getMinutes() + 30
                    ),
                },
            ]);
        } else {
            setSelectedSlots([
                {
                    dStartTime: new Date(selectedDate).setHours(6),
                    dEndTime: new Date(selectedDate).setHours(6, 30),
                },
            ]);
        }
    };

    const handleRepeatDays = (day) => {
        setSelectedDays((prev) => {
            if (prev.includes(day)) return prev.filter((d) => d !== day);
            else return [...prev, day];
        });
    };

    const handleRepeatFrequency = (frequency) => {
        setRepeatFrequency(frequency.value);
    };

    const handleRepeatAfter = (after) => {
        setRepeatAfter(after.value);
    };

    const handleAlertChange = (alert) => {
        setAlertTime(alert.value);
    };

    const handleSave = (e) => {
        e.preventDefault();

        const allSelectedSlots = selectedSlots?.map(
            ({ dStartTime, dEndTime }) => ({
                sStartTime: moment(dStartTime).format('HH:mm'),
                sEndTime: moment(dEndTime).format('HH:mm'),
            })
        );

        const errors = formErrors;

        const dSelectedDate = new Date(selectedDate);
        dSelectedDate.setHours(0, 0, 0, 0);

        // Validate Start Date
        if (!selectedDate)
            errors.dStartDate = t('availability.errors.start_date_required');
        else if (selectedDate < new Date().setHours(0, 0, 0, 0))
            errors.dStartDate = t('availability.errors.past_start_date');
        else errors.dStartDate = '';

        // Validate End Date
        if (repeatType !== 'once' && !endDate)
            errors.dEndDate = t('availability.errors.end_date_required');
        else if (repeatType !== 'once' && endDate < dSelectedDate)
            errors.dEndDate = t(
                'availability.errors.end_date_before_start_date'
            );
        else errors.dEndDate = '';

        // Validate Slots
        if (!selectedSlots?.length)
            errors.aSlots = t('availability.errors.slots_required');
        else errors.aSlots = '';

        // Validate Repeat Type
        if (!repeatType)
            errors.sType = t('availability.errors.repeat_type_required');
        else errors.sType = '';

        // Validate Alert Time
        if (!alertTime)
            errors.nAlertBefore = t('availability.errors.alert_time_required');
        else errors.nAlertBefore = '';

        // Validate Repeat After
        if (repeatType === 'multiple' && !repeatAfter)
            errors.nRepeat = t('availability.errors.repeat_after_required');
        else errors.nRepeat = '';

        // Validate Repeat Frequency
        if (repeatType === 'multiple' && !repeatFrequency)
            errors.sRepeatFrequency = t(
                'availability.errors.repeat_after_required'
            );
        else errors.sRepeatFrequency = '';

        // Validate Repeat Days
        if (repeatType === 'multiple' && !selectedDays.length)
            errors.aChoosenDays = t('availability.errors.repeat_days_required');
        else errors.aChoosenDays = '';

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) {
            setHasError(true);
            return;
        } else setHasError(false);

        const payload = {
            dStartDate: moment(selectedDate).format('YYYY-MM-DD'),
            dEndDate: moment(
                repeatType === 'once' ? selectedDate : endDate
            ).format('YYYY-MM-DD'),
            aSlots: allSelectedSlots,
            sType: repeatType,
            nAlertBefore: alertTime,
        };
        if (repeatType === 'multiple') {
            payload['nRepeat'] = repeatAfter;
            payload['aChoosenDays'] = selectedDays;
            payload['sRepeatFrequency'] = repeatFrequency;
        }
        mutateSetAvailability(payload);
    };
    const calendar = useMemo(() => {
        return (
            <FullCalendar
                firstDay={1}
                editable={false}
                selectable={false}
                dayMaxEvents={currentView === 'dayGridMonth' ? 3 : true}
                events={events}
                // select={handleSelect}
                selectLongPressDelay={0}
                eventLongPressDelay={0}
                selectMirror={false}
                eventContent={renderEventContent}
                dateClick={handleDateClick}
                headerToolbar={{
                    start: 'prev title next',
                    end: 'dayGridMonth timeGridWeek timeGridDay',
                }}
                titleFormat={{ month: 'long', year: 'numeric' }}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                views={{
                    dayGridMonth: {},
                    timeGridWeek: {
                        allDaySlot: false,
                        slotLabelFormat: {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        },
                        dayHeaderFormat: {
                            weekday: 'short',
                            day: 'numeric',
                        },
                    },
                    timeGridDay: {
                        allDaySlot: false,
                        slotLabelFormat: {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        },
                        dayHeaderFormat: {
                            weekday: 'long',
                            day: '2-digit',
                        },
                    },
                }}
                datesSet={(info) => setCurrentView(info.view.type)}
                nowIndicator={true}
                dayCellContent={({ date, view }) => {
                    if (view.type === 'dayGridMonth') {
                        if (date < new Date().setHours(0, 0, 0, 0))
                            return (
                                <div className='past-date'>
                                    {date.getDate()}
                                </div>
                            );
                        else return date.getDate();
                    }
                }}
                locales={[esLocale, enLocale, deLocale]}
                locale={LOCALE_MAP[i18n.language.split('-')[0]]}
            />
        );
    }, [i18n.language, events, currentView]);
    useEffect(() => {
        const calWrapper = document.querySelector('.fc-scroller-harness');
        const cal = document.querySelector('.fc-scroller');

        const handleScroll = () => {};

        calWrapper.addEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            <div className='calendar-screen-wrapper'>
                <BreadcrumbAction lastPage={t('dashboard_nav.availability')} />
                <div className='content-wrapper calendar-container'>
                    <div className='custom-calendar scrolling-horizontal'>
                        {calendar}
                    </div>
                </div>
                <ModalPopUp
                    show={modalShow}
                    className='calendar-popup'
                    onHide={() => {
                        setModalShow(false);
                        setHasError(false);
                        setSelectedDate(null);
                    }}
                    modalcontent={
                        <>
                            <h3 className='popup-title text-center '>
                                {t('availability.availability_details')}
                            </h3>
                            <div className='pb-3'>
                                <DateSelector
                                    label={t('availability.start_date') + '*'}
                                    placeholder={t(
                                        'availability.select_date_placeholder'
                                    )}
                                    startYear={new Date().getFullYear()}
                                    endYear={new Date().getFullYear() + 2}
                                    value={selectedDate}
                                    dateFormat='EEEE, MMMM dd'
                                    handleDateChange={handleDateChange}
                                    errorMessage={formErrors.dStartDate}
                                />
                            </div>

                            {repeatType && repeatType !== 'once' ? (
                                <DateSelector
                                    label={t('availability.end_date') + '*'}
                                    placeholder={t(
                                        'availability.select_date_placeholder'
                                    )}
                                    startYear={new Date().getFullYear()}
                                    endYear={new Date().getFullYear() + 2}
                                    value={endDate}
                                    dateFormat='EEEE, MMMM dd'
                                    handleDateChange={handleEndDateChange}
                                    errorMessage={formErrors.dEndDate}
                                />
                            ) : (
                                ''
                            )}

                            <div
                                className={
                                    'slot-repeater-select' +
                                    (formErrors.sType ? ' error-input' : '')
                                }
                            >
                                {repeatTypes.map((type) => {
                                    return (
                                        <Form.Check
                                            className='radio-button check-box'
                                            name={'slot-repeater'}
                                            type='radio'
                                            id={type.id}
                                            label={
                                                t(type.label) +
                                                (type.value === 'weekly'
                                                    ? t(
                                                          `availability.days.${selectedDay}`
                                                      )
                                                    : '')
                                            }
                                            key={type.id}
                                            checked={repeatType === type.value}
                                            onChange={(e) => {
                                                setRepeatType(type.value);
                                                setSelectedDays([]);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <div className='time-slot-wrapper'>
                                {!selectedSlots?.length ? (
                                    <button
                                        className='add-time-btn'
                                        onClick={handleEmptySlots}
                                    >
                                        <img
                                            src='/images/add-time.svg'
                                            alt=''
                                        />{' '}
                                        {t('availability.add_time')}
                                    </button>
                                ) : (
                                    <>
                                        {selectedSlots && (
                                            <TimeSelector
                                                selectedSlots={selectedSlots}
                                                setSelectedSlots={
                                                    setSelectedSlots
                                                }
                                                selectedDate={selectedDate}
                                                maxSlots={maxSlots}
                                                allSlots={allSlots}
                                            />
                                        )}
                                    </>
                                )}
                                {formErrors.aSlots ? (
                                    <FormErrorLabel
                                        message={formErrors.aSlots}
                                    />
                                ) : null}
                            </div>
                            {formErrors.sType ? (
                                <FormErrorLabel message={formErrors.sType} />
                            ) : null}
                            {repeatType === 'multiple' ? (
                                <>
                                    <div className='drop-repeat-wrapper'>
                                        <p className='sm'>
                                            {t('availability.repeat_every')}
                                        </p>
                                        <div className='repeat-dropdown'>
                                            <DropDownSelector
                                                dropDownLabel=''
                                                placeholder={t(
                                                    'availability.select_placeholder'
                                                )}
                                                options={repeatAfterOptions}
                                                handleOnChange={
                                                    handleRepeatAfter
                                                }
                                                value={{
                                                    selectedValue: repeatAfter,
                                                }}
                                            />
                                            <DropDownSelector
                                                dropDownLabel=''
                                                placeholder={t(
                                                    'availability.select_placeholder'
                                                )}
                                                options={frequencyOptions}
                                                handleOnChange={
                                                    handleRepeatFrequency
                                                }
                                                value={{
                                                    selectedValue:
                                                        repeatFrequency,
                                                }}
                                            />
                                        </div>
                                        {formErrors.nRepeat ||
                                        formErrors.sRepeatFrequency ? (
                                            <FormErrorLabel
                                                message={
                                                    formErrors?.nRepeat ||
                                                    formErrors.sRepeatFrequency
                                                }
                                            />
                                        ) : null}
                                    </div>
                                    <div className='drop-repeat-wrapper'>
                                        <p className='sm'>
                                            {t('availability.repeat_on')}
                                        </p>
                                        <div className='repeat-day-week'>
                                            {weekDays.map((day) => {
                                                return (
                                                    <div
                                                        key={day.value}
                                                        className='day-card'
                                                        onClick={() => {
                                                            handleRepeatDays(
                                                                day.value
                                                            );
                                                        }}
                                                    >
                                                        <input
                                                            name={day.name}
                                                            type='checkbox'
                                                            checked={selectedDays.includes(
                                                                day.value
                                                            )}
                                                            onChange={() => {}}
                                                        />
                                                        <div className='day'>
                                                            {day.symbol}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {formErrors.aChoosenDays ? (
                                            <FormErrorLabel
                                                message={
                                                    formErrors.aChoosenDays
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}

                            <DropDownSelector
                                whenOpen={() => {}}
                                dropDownLabel={
                                    <div className='d-flex'>
                                        <img
                                            src='/images/alert-icon.svg'
                                            alt=''
                                        />{' '}
                                        {t('availability.alert')}
                                    </div>
                                }
                                placeholder={t(
                                    'availability.select_placeholder'
                                )}
                                options={alertOptions}
                                handleOnChange={handleAlertChange}
                                value={{ selectedValue: alertTime }}
                            />
                            {formErrors.nAlertBefore ? (
                                <FormErrorLabel
                                    message={formErrors.nAlertBefore}
                                />
                            ) : null}
                            {hasError && (
                                <div className='error-msg edit-profile-error justify-content-start'>
                                    <img
                                        src='/images/icons/error-ic.svg'
                                        alt='error-ic'
                                    />
                                    <p className='error'>
                                        {t('you_are_missing')}
                                    </p>
                                </div>
                            )}
                            <div className='calendar-popup-action'>
                                <div>
                                    {showDelete && (
                                        <button
                                            onClick={() => {
                                                setDeleteModalShow(true);
                                                setModalShow(false);
                                            }}
                                            className='simple-btn'
                                        >
                                            <img
                                                src='/images/delete-red.svg'
                                                alt=''
                                            />
                                        </button>
                                    )}
                                </div>
                                <div className='custom-pop-up-action'>
                                    <button
                                        onClick={() => {
                                            setModalShow(false);
                                            setSelectedDate(null);
                                            setHasError(false);
                                        }}
                                        className='simple-btn'
                                    >
                                        {t('availability.cancel_btn')}
                                    </button>
                                    <button
                                        className={
                                            isMutating ||
                                            !selectedSlots?.length ||
                                            !selectedDate ||
                                            !repeatType ||
                                            !alertTime ||
                                            (repeatType !== 'once' &&
                                                !endDate) ||
                                            (repeatType === 'multiple' &&
                                                (!selectedDays.length ||
                                                    !repeatAfter ||
                                                    !repeatFrequency))
                                                ? 'primary-btn btn-disabled'
                                                : 'primary-btn'
                                        }
                                        onClick={handleSave}
                                        disabled={isMutating}
                                    >
                                        {isMutating ? (
                                            <LoadingButton color='White' />
                                        ) : (
                                            t('availability.save_btn')
                                        )}
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                />
                <ModalPopUp
                    show={deleteModalShow}
                    onHide={() => {
                        setDeleteModalShow(false);
                    }}
                    modalcontent={
                        <>
                            <img src='/images/icons/warn-ic.svg' alt='' />

                            <h3 className='popup-title'>
                                {t('popup_content.are_you')}
                            </h3>

                            <p className='sm'>
                                {t('popup_content.are_you_desc')}
                            </p>

                            <div className='popup-action'>
                                <button
                                    onClick={() => {
                                        setDeleteModalShow(false);
                                    }}
                                    className='primary-btn '
                                >
                                    {t('popup_content.no_dont')}
                                </button>

                                <button
                                    className='simple-btn'
                                    onClick={mutateDeleteAvailability}
                                    disabled={isDeleting}
                                >
                                    {isDeleting ? (
                                        <LoadingButton />
                                    ) : (
                                        t('popup_content.yes_delete')
                                    )}
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
}
function renderEventContent(eventInfo) {
    return <CustomEvent eventInfo={eventInfo} />;
}
export default CustomCalendar;
