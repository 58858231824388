import {
    CLEAR_NOTIFICATIONS,
    DELETE_MESSAGE_NOTIFICATION,
    DELETE_NOTIFICATION,
    DELETE_REQUEST_NOTIFICATION,
    DELETE_SCHEDULED_NOTIFICATIONS,
    MARK_ALL_SEEN,
    UPDATE_NOTIFICATION,
} from '../constants/redux-events';

export const initialNotificationState = [
    {
        sSenderName: '',
        _id: '',
        iConversationId: '',
        sContent: '',
        iRecipient: '',
        sNotificationType: '',
        // bIsRead: false,
        dCreatedAt: '',
        dUpdatedAt: '',
    },
];
// export const initialNotificationState = [];

const notification = (state = initialNotificationState, { type, payload }) => {
    switch (type) {
        case UPDATE_NOTIFICATION:
            return [...state, payload];
        case DELETE_NOTIFICATION:
            let aCopy = [...state];
            let newArray = aCopy.filter(
                (item) => item.iConversationId !== payload
            );
            return newArray;
        // return state.iConversationId !== payload
        //     ? state
        //     : initialNotificationState;
        case DELETE_REQUEST_NOTIFICATION:
            let aCopy2 = [...state];
            let newArray2 = aCopy2.filter((item) => {
                return (
                    item.sNotificationType !== 'APPOINTMENT' &&
                    item.sNotificationType !== 'REJECTED' &&
                    item.sNotificationType !== 'ACCEPTED' &&
                    item.sNotificationType !== 'CANCELED' &&
                    item.sNotificationType !== 'RESCHEDULED'
                );
            });
            return newArray2;
        // if (
        //     state.sNotificationType === 'APPOINTMENT' ||
        //     state.sNotificationType === 'REJECTED' ||
        //     state.sNotificationType === 'ACCEPTED' ||
        //     state.sNotificationType === 'CANCELED' ||
        //     state.sNotificationType === 'RESCHEDULED'
        // ) {
        //     return initialNotificationState;
        // }
        // return state;
        case DELETE_SCHEDULED_NOTIFICATIONS:
            let aCopy4 = [...state];
            let newArray4 = aCopy4.filter((item) => {
                return item.sNotificationType !== 'ACCEPTED';
            });
            return newArray4;
        // return state.sNotificationType !== 'ACCEPTED'
        //     ? state
        //     : initialNotificationState;
        case DELETE_MESSAGE_NOTIFICATION:
            let aCopy3 = [...state];
            let newArray3 = aCopy3.filter((item) => {
                return (
                    item.sNotificationType !== 'TEXT' &&
                    item.sNotificationType !== 'IMAGE' &&
                    item.sNotificationType !== 'AUDIO'
                );
            });
            return newArray3;
        // if (
        //     state.sNotificationType === 'TEXT' ||
        //     state.sNotificationType === 'IMAGE' ||
        //     state.sNotificationType === 'AUDIO'
        // ) {
        //     return initialNotificationState;
        // }
        // return state;
        case CLEAR_NOTIFICATIONS:
            return state;

        case MARK_ALL_SEEN:
            return [...payload];

        default:
            return state;
    }
};

export default notification;
